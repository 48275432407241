<template>
    <div>
        <v-chip :outlined="isOutlined" :color="chipColor" @click="$emit('click')">
            <template v-if="isLoading">
            <v-progress-circular indeterminate class="mr-2" size="20" width="2"></v-progress-circular>{{ label }}
            </template>
            <template v-else>
            <v-icon left :color=iconColor>{{ icon }}</v-icon>{{ label }}
            </template>
        </v-chip>
    </div>
</template>

<script>
export default {
    name: 'IconChip',
    props: {
        icon: String,
        label: String,
        iconMargin: String,
        isLoading: Boolean,
        chipColor: {
            type: String,
            default: 'grey darken-3'
        },
        iconColor: {
            type: String,
            default: 'black'
        },
        isOutlined: {
            type: Boolean,
            default: true
        }
    }
};
</script>